import pendingList from "./pending-list/PendingList.vue";
import orderPendingList from "./order-grid-pending/OrderPendingList.vue";

export default {
  components: {
    pendingList,
    orderPendingList
  },
  data: () => ({
    showGrid: false,
    menu: false,
    order: {
      id: "",
      status: "",
      number: "",
      deliveryAddress: "",
      orderType: {},
      deliveryDate: "",
      scheduleRange: "",
      cityId: "",
      stateItem: "",
      providerId: "",
      pendingDays: ""
    },
    patient: {
      id: "",
      documentType: "",
      document: "",
      fullname: ""
    },
    plu: "2",
    description: "",
    count: "",
    medicineList: []
  }),
  methods: {
    handleOrder(order) {
      this.showGrid = !this.showGrid;

      this.order.id = order.id;
      this.order.number = order.number;
      this.order.deliveryDate = order.shippingDate;
      this.order.pendingDays = order.pendingDays;
      this.order.status = this.getStatusName(order.status);
      this.order.orderType =
        order.type === "EXCLUSIVE" ? "EXCLUSIVO" : "INTEGRADO";

      this.patient.id = order.idPatient.id;
      this.patient.documentType = order.idPatient.idIdentificationType.name;
      this.patient.document = order.idPatient.identification;
      this.patient.fullname = order.idPatient.name;

      this.medicineList = [];

      order.orderDetails.forEach(orderDetail => {
        if (orderDetail.orderDetailMedicines) {
          let newMedicine = orderDetail.orderDetailMedicines.map(medicine => {
            medicine.orderId = order.id;
            medicine.orderNumber = orderDetail.code;
            medicine.reason = medicine.idPendingReason
              ? medicine.idPendingReason.id
              : "";
            medicine.disablePendingReason = false;
            return medicine;
          });

          this.medicineList = [...this.medicineList, ...newMedicine];
        }
      });
    },
    getStatusName(status) {
      switch (status) {
        case "PARTIALLY_CHECKED_IN":
          return "FACTURADO PARCIAL";

        case "CHECKED_IN":
          return "FACTURADO";

        case "PARTIALLY_CHECKED_OUT":
          return "PLANILLADO PARCIAL";

        case "CHECKED_OUT":
          return "PLANILLADO";

        case "SHIPPED":
          return "ENTREGADO";

        case "CANCELED":
          return "CANCELADO";

        default:
          return "AGENDADO";
      }
    },
    closePending(show) {
      this.showGrid = show;
    }
  }
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showGrid,
              expression: "showGrid",
            },
          ],
          attrs: { "justify-center": "", wrap: "" },
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "material-card",
                { attrs: { color: "primary", title: "Información pendiente" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", [
                        _c("h3", [_vm._v(_vm._s(_vm.$t("pending.detail")))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { disabled: true, label: "Nro. Pedido" },
                            model: {
                              value: _vm.order.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "number", $$v)
                              },
                              expression: "order.number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: {
                              disabled: true,
                              label: "Fecha de entrega",
                            },
                            model: {
                              value: _vm.order.deliveryDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "deliveryDate", $$v)
                              },
                              expression: "order.deliveryDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { disabled: true, label: "Días de retraso" },
                            model: {
                              value: _vm.order.pendingDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "pendingDays", $$v)
                              },
                              expression: "order.pendingDays",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { disabled: true, label: "Tipo de pedido" },
                            model: {
                              value: _vm.order.orderType,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "orderType", $$v)
                              },
                              expression: "order.orderType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: {
                              disabled: true,
                              label: "Estado del pedido",
                            },
                            model: {
                              value: _vm.order.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "status", $$v)
                              },
                              expression: "order.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$t("pending.patient-data"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: {
                              disabled: true,
                              label: "Tipo de documento",
                            },
                            model: {
                              value: _vm.patient.documentType,
                              callback: function ($$v) {
                                _vm.$set(_vm.patient, "documentType", $$v)
                              },
                              expression: "patient.documentType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { disabled: true, label: "Documento" },
                            model: {
                              value: _vm.patient.document,
                              callback: function ($$v) {
                                _vm.$set(_vm.patient, "document", $$v)
                              },
                              expression: "patient.document",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "purple-input",
                            attrs: { disabled: true, label: "Nombre" },
                            model: {
                              value: _vm.patient.fullname,
                              callback: function ($$v) {
                                _vm.$set(_vm.patient, "fullname", $$v)
                              },
                              expression: "patient.fullname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c("order-pending-list", {
                            attrs: {
                              orderId: _vm.order.id,
                              medicineList: _vm.medicineList,
                            },
                            on: { "close-pending": _vm.closePending },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showGrid,
              expression: "!showGrid",
            },
          ],
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "material-card",
                { attrs: { color: "primary", title: "Pendientes" } },
                [
                  _c(
                    "v-form",
                    [
                      _c("pending-list", {
                        ref: "pendingList",
                        on: { "row-click": _vm.handleOrder },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }